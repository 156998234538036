import React, { useState, useEffect, useCallback} from 'react';
import { DataGrid,  GridColumnMenu, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, useGridApiContext, GridToolbarContainer, getGridNumericOperators, getGridDateOperators } from '@mui/x-data-grid';
import { Alert, Box, Button, Dialog, DialogTitle, DialogContent, 
        DialogContentText, DialogActions, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, 
        List, ListItem, OutlinedInput, Snackbar, Select, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { red, blue, green, amber, grey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import * as moment from 'moment';
import 'moment-timezone'
import CustomToolbar from '../Excel';
import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/Pending';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FunctionsIcon from '@mui/icons-material/Functions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useAuthStore from '../../auth/authStore';
import config from '../../config.json';
import EstimatorProjects from './EstimatorProjects';
import fetchProjectCounts from './fetchProjectCounts';
import fetchEstimatorProjectCounts from './fetchEstimatorProjectCounts';
import CountUp from '../CountUp';
import MainCard from '../MainCard';
import betweenFilterOperator from '../betweenFilter';
import betweenDateFilterOperator from '../betweenDateFilter';
import Slide from '@mui/material/Slide';


const fieldNames = ['contract_id', 'work_order_number', 'work_order_title', 'proposal_status', 'estimator', 'project_manager', 'RFP', 'job_walk',
'proposal_due_date', 'proposal_date_submitted', 'inquiry_subcon_date', 'budget_status', 'reviewer', 'review_date', 'estimated_prop_amt', 'estimated_cost', 'NTP_date',
'NTP_amount', 'estimated_cost_after_ntp', 'notes']

const fieldNameMapping = {'contract_id':"Contract ID", 
'work_order_number': "WO #", 
'work_order_title': "WO Title", 
'proposal_status': "Proposal Status", 
'estimator': "Estimator", 
'project_manager': "PM", 
'RFP': "RFP Date", 
'job_walk': "Job Walk Date",
'proposal_due_date': "Due Date", 
'proposal_date_submitted': "Submit Date", 
'inquiry_subcon_date': "Inquiry Subcon Date", 
'budget_status': "Budget Status", 
'reviewer': "Reviewer", 
'review_date': "Review Date", 
'estimated_prop_amt': "Proposal Amount", 
'estimated_cost': "Estimated Cost", 
'NTP_date': "NTP Date",
'NTP_amount': "NTP Amount", 
'estimated_cost_after_ntp': "Estimated Cost (NTP)", 
'notes': "Notes",
"fixed_markup": "Fixed Markup",
"fixed_markup_perc": "Markup %",
"gross_profit_after_ntp": "Gross Profit %"}

const fields = {id: null, contract_id: '', work_order_number: '',work_order_title: '',proposal_status: '',estimator: '',project_manager: '',RFP: null,job_walk: null,
proposal_due_date: null,proposal_date_submitted: null,inquiry_subcon_date: null,inquiry_subcon_status: '',
budget_status: '',reviewer: '',review_date:null,estimated_prop_amt: null, NTP_date:null, NTP_amount: null, estimated_cost: null, estimated_cost_after_ntp: null, notes: null}

const fieldLabels = [{name: 'contract_id', label: 'Contract ID' }, {name: 'work_order_number', label: 'WO #' }, {name: 'work_order_title', label: 'WO Title' }, 
{name: 'estimator', label: 'Estimator' }, {name: 'project_manager', label: 'Project Manager' }, {name: 'RFP', label: 'RFP Date' }, {name: 'job_walk', label: 'Job Walk Date' }, {name: 'proposal_due_date', label: 'Proposal Due Date' }, {name: 'proposal_status', label: 'Proposal Status' }, {name: 'proposal_date_submitted', label: 'Date Submitted' }, {name: 'inquiry_subcon_date', label: 'Inquiry Subcon Date' }, 
{name: 'inquiry_subcon_status', label: 'Inquiry Subcon Status'}, {name: 'budget_status', label: 'Budget Status' }, 
{name: 'reviewer', label: 'Reviewer' }, {name: 'review_date', label: 'Review Date'},
{name: "estimated_prop_amt", label: 'Proposal Amount'},
{name: "estimated_cost", label: 'Estimated Cost'},
{name: "NTP_date", label: 'NTP Date'},
{name: "NTP_amount", label: 'NTP Amount'},
{name: "estimated_cost_after_ntp", label: 'Estimated Cost (NTP)'},{name: 'notes', label: 'Notes' }
]

const dateFields = ["RFP", "job_walk",  "proposal_due_date", "proposal_date_submitted", "inquiry_subcon_date", "review_date","NTP_date"];
const peopleFields = ["estimator", "project_manager",  "reviewer"];
const statusFields = ["proposal_status", "budget_status",  "inquiry_subcon_status"];
const numberFields = ['NTP_amount', 'estimated_cost', 'estimated_cost_after_ntp', 'estimated_prop_amt']
const booleanFields = [];
const budgetStatusFields = ["budget_status"];

const statusMapping = {
    'IN_PROGRESS': 'In Progress',
    'SUBMITTED': 'Submitted',
    'NTP': 'NTP',
    'ON_HOLD': 'On Hold',
    'CANCELLED': 'Cancelled'
};

function commafy( num ) {
    var str = num.toString().split('.');
    if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }
  
function ccyFormat(num) {
    return num.toFixed(2);
  }

function RowAddTextField({ name, label, autoFocus, value, onChange }) {
    return (
      <TextField
        margin="dense"
        name={name}
        label={label}
        type="text"
        fullWidth
        value={value || ''}
        onChange={onChange}
      />
    );
  }

function TableComponent() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(fields);
    const [editedRows, setEditedRows] = useState({});
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [summaryDrawerRow, setSummaryDrawerRow] = useState(null);
    const [summaryDrawerOpen, setSummaryDrawerOpen] = useState(false);
    const [summaryEditableValues, setSummaryEditableValues] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [peopleMapping, setPeopleMapping] = useState([]);
    const [peopleOptions, setPeopleOptions] = useState([]);
    const [snackbar, setSnackbar] = useState(null);
    const [ntpSnackbar, setNTPSnackbar] = useState({
        open: false,
        Transition: Slide,
      });
    const [ntpValues, setNTPValues] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const initialProjectCounts = Object.keys(statusMapping).reduce((acc, status) => {
        acc[status] = 0; // Initialize count as 0 for each status
        return acc;
      }, {});
    const [projectCounts, setProjectCounts] = useState(initialProjectCounts);
    const [EstimatorProjectCount, setEstimatorProjectCount] = useState([]);
    const [numprojects, setNumProjects] = useState(0);

    const { username, role } = useAuthStore();
    const editableRoles = ['admin', 'editor'];
    const isEditable = editableRoles.includes(role);

    useEffect(() => {
        const fetchData = async () => {
            try{
        const projectsResponse = await axios.get(`${config.apiBaseUrl}/api/projects/`, {withCredentials: true});
        const peopleResponse = await axios.get(`${config.apiBaseUrl}/api/people/`, {withCredentials: true});

        const projects = projectsResponse.data;
        const people = peopleResponse.data;

        const formattedProjects = projects.map((project) => {
            const formattedProject = { id: project.id };
    
            Object.keys(project).forEach((key) => {
                if (dateFields.includes(key)) {
                    // Check if the value is null or not a date before converting
                    formattedProject[key] = project[key] ? moment.tz(project[key], 'America/Los_Angeles').toISOString() : project[key];
                } else if (peopleFields.includes(key)) {
                    // If it's a peopleField, replace null with an empty string
                    formattedProject[key] = project[key] === null ? '' : project[key];
                } else {
                    // Just copy the value
                    formattedProject[key] = project[key];
                }
            });
            return formattedProject;
        });
        const formattedPeople = people.map((person) => ({
        id: person.id,
        ...person,
        }));

        const peopleMapping = {'':''};
        const peopleDropdownOptions = [{value: '', label: 'N/A'}];

        formattedPeople.forEach(person => {
        peopleMapping[person.id] = `${person.first_name} ${person.last_name}`;
        peopleDropdownOptions.push({value: person.id, label: `${person.first_name} ${person.last_name}`});
        });
        
        setNumProjects(formattedProjects.length);
        setRows(formattedProjects);
        setPeopleMapping(peopleMapping);
        setPeopleOptions(peopleDropdownOptions);
        setLoading(false);

        fetchProjectCounts(config, setProjectCounts)
        fetchEstimatorProjectCounts(config, setEstimatorProjectCount, EstimatorProjectCount);

        } catch (error) {
            console.error('Error fetching data: ', error);
    };
};
    
        fetchData();
      }, []);

    useEffect(() => {
    }, [editedRows]);

    useEffect(() => {
        if (summaryDrawerRow) {
          setSummaryEditableValues(summaryDrawerRow);
        }
      }, [summaryDrawerRow, summaryDrawerOpen]);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });
    
    const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    cellClassName: 'font-tabular-nums',
    };

    const percentage = {
        type: 'number',
        width: 130,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return `${params.value.toLocaleString()} %`
        },
        cellClassName: 'font-tabular-nums'
        };
    
    function dateformatter (params) {
        if (params.value === null || params.value === '') {
            return '';
        }
        return moment(params.value).isValid() ? moment(params.value).format('MM/DD/YYYY') : '';
    }

    function monthformatter (params) {
        if (params.value === null || params.value === '') {
            return '';
        }
        return moment(params.value).isValid() ? moment(params.value).format('MM/YYYY') : '';
    }

    const formatValue = (key, value) => {
        if (value === null || value === undefined) {
            return '-'
        }
        if (dateFields.includes(key)) {
            // Format the date to a readable string format
            return dateformatter(value);
        }
        else if (peopleFields.includes(key)) {
            return peopleMapping[value]
        }
        else if (numberFields.includes(key)) {
            return `$${commafy(ccyFormat(Number(value)))}`
        }
        // Handle other types of values if needed
        return value.toString();
    };

    const statusOptions = [
        { value: 'IN_PROGRESS', label: 'In Progress' },
        { value: 'SUBMITTED', label: 'Submitted' },
        { value: 'ON_HOLD', label: 'On Hold' },
        { value: 'CANCELLED', label: "Cancelled"},
        { value: 'NTP', label: 'NTP' },
        { value: null, label: ''}
    ]

    const budgetStatusOptions = [
        { value: 'NOT_READY', label: 'Not Ready' },
        { value: 'READY', label: 'Ready' },
        { value: null, label: ''}
    ]

    function getChipProps(params) {
        if (params.value === "IN_PROGRESS") {
          return {
            icon: <PendingIcon style={{ fill: red[600] }} />,
            label: "In Progress",
            style: {
              borderColor: red[600],
              color: red[600]
            }
          };
        } else if (params.value === "SUBMITTED")
        {
          return {
            icon: <ArrowUpwardIcon style={{ fill: blue[500] }} />,
            label: "Submitted",
            style: {
                borderColor: blue[500],
                color: blue[500]
            }
            };
        }
        else if (params.value === "ON_HOLD")
        {
          return {
            icon: <AutorenewIcon style={{ fill: amber[700] }} />,
            label: "On Hold",
            style: {
                borderColor: amber[700],
                color: amber[700]
            }
            };
        }
        else if (params.value === "NTP")
        {
          return {
            icon: <CheckIcon style={{ fill: green[500] }} />,
            label: "NTP",
            style: {
                borderColor: green[500],
                color: green[500]
            }
            };
        }
        else if (params.value === "CANCELLED")
        {
          return {
            icon: <CancelIcon style={{ fill: grey[500] }} />,
            label: "Cancelled",
            style: {
                borderColor: grey[500],
                color: grey[500]
            }
            };
        }
        }

    const columns = [
        { 
            headerName: "ID", 
            field: "id"
        },
        {
            field: "Summary",
            width: 80,
            renderCell: (params) => {
              return (
                <IconButton onClick={(event) => {
                    handleOpenSummaryDrawer(params.row);
                  }}>
                    <SquareRoundedIcon
                  style={{marginLeft: '10px', maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'}}
                  variant="contained"
                  color="primary"
                />
                </IconButton>
              );
            }
        },
        { 
            headerName: "Contract ID", 
            field: "contract_id",
            minWidth: 150,
            editable: isEditable
        },
        { 
            headerName: "WO #", 
            field: "work_order_number",
            minWidth: 165,
            editable: isEditable
        },
        { 
            headerName: "WO Title", 
            field: "work_order_title",
            minWidth: 500,
            flex: 1,
            editable: isEditable  
        },
        { 
            headerName: "Proposal Status", 
            field: "proposal_status",
            editable: isEditable,
            type: 'singleSelect',
            align:'left',
            minWidth: 140,
            valueOptions: statusOptions,
            renderCell: (params) => {
                if (!params.value)
                {return null}
                else {
                return <Chip size="small" variant="outlined" {...getChipProps(params)}/>;
                }
            }
        },
        { 
            headerName: "Estimator", 
            field: "estimator",
            type: 'singleSelect',
            minWidth: 125,
            valueFormatter: function(params) {
                return peopleMapping[params.value]
            },
            valueOptions: peopleOptions,
            editable: isEditable
        },
        { 
            headerName: "PM", 
            field: "project_manager",
            editable: isEditable,
            type: 'singleSelect',
            minWidth: 125,
            valueFormatter: function(params) {
                return peopleMapping[params.value]
            },
            valueOptions: peopleOptions,
        },
        { 
            headerName: "RFP Date", 
            field: "RFP",
            editable: isEditable,
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            minWidth: 100,
            valueFormatter: dateformatter
        },
        { 
            headerName: "Job Walk Date", 
            field: "job_walk",
            editable: isEditable,
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            minWidth: 115,
            valueFormatter: dateformatter
        },
        { 
            headerName: "Due Date", 
            field: "proposal_due_date",
            editable: isEditable,
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            minWidth: 100,
            valueFormatter: dateformatter
        },
        { 
            headerName: "Submit Date",
            field: "proposal_date_submitted",
            editable: isEditable,
            minWidth: 100,
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            valueFormatter: dateformatter
        },
        { 
            headerName: "Inquiry Subcon Date", 
            field: "inquiry_subcon_date",
            editable: isEditable,
            minWidth: 150,
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            valueFormatter: dateformatter
        },
        { 
            headerName: "Budget Status", 
            field: "budget_status",
            editable: isEditable,
            minWidth: 110,
            type: 'singleSelect',
            valueOptions: budgetStatusOptions
        },
        { 
            headerName: "Reviewer", 
            field: "reviewer",
            editable: isEditable,
            minWidth: 125,
            type: 'singleSelect',
            valueFormatter: function(params) {
                return peopleMapping[params.value]
            },
            valueOptions: peopleOptions,
        },
        { 
            headerName: "Review Date", 
            field: "review_date",
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            minWidth: 100,
            valueFormatter: function (params) {
                if (!params.value || !moment(params.value).isValid()) {
                    return null;
                }
                return moment(params.value).isValid() ? moment(params.value).format('MM/DD/YYYY') : '';
            },
            editable: isEditable
        },
        { 
            headerName: "Proposal Amount", 
            field: "estimated_prop_amt",
            type: 'number',
            filterOperators: getGridNumericOperators().concat(betweenFilterOperator),
            minWidth: 135,
            editable: isEditable,
            ...usdPrice
        },
        { 
            headerName: "Estimated Cost", 
            field: "estimated_cost",
            type: 'number',
            filterOperators: getGridNumericOperators().concat(betweenFilterOperator),
            minWidth: 100,
            editable: isEditable,
            ...usdPrice
            
        },
        { 
            headerName: "Estimated Markup", 
            field: "estimated_markup",
            minWidth: 105,
            valueGetter: getEstimatedMarkup,
            ...usdPrice
        },
        { 
            headerName: "Markup %", 
            field: "markup_perc",
            minWidth: 70,
            valueGetter: getEstimatedMarkupPercentage,
            ...percentage
        },
        { 
            headerName: "Gross Profit %", 
            field: "gross_profit",
            minWidth: 75,
            valueGetter: getGrossProfit,
            ...percentage
        },
        { 
            headerName: "NTP Date", 
            field: "NTP_date",
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            minWidth: 100,
            valueFormatter: function (params) {
                if (params.value === null || params.value === '') {
                    return ''; // return an empty string for null or blank dates
                }
                return moment(params.value).isValid() ? moment(params.value).format('MM/DD/YYYY') : '';
            },
            editable: isEditable
        },
        { 
            headerName: "NTP Month", 
            field: "ntp_month",
            minWidth: 100,
            type: 'date',
            filterOperators: getGridDateOperators().concat(betweenDateFilterOperator),
            valueGetter: (params) => {
                return params.row.NTP_date
            },
            valueFormatter: monthformatter
        },
        { 
            headerName: "NTP Amount", 
            field: "NTP_amount",
            type: 'number',
            filterOperators: getGridNumericOperators().concat(betweenFilterOperator),
            minWidth: 125,
            editable: isEditable,
            ...usdPrice
        },
        { 
            headerName: "Estimated Cost (NTP)", 
            field: "estimated_cost_after_ntp",
            type: 'number',
            filterOperators: getGridNumericOperators().concat(betweenFilterOperator),
            minWidth: 150,
            editable: isEditable,
            ...usdPrice
        },
        { 
            headerName: "Fixed Markup", 
            field: "fixed_markup",
            minWidth: 125,
            valueGetter: getFixedMarkup,
            ...usdPrice
        },
        { 
            headerName: "Markup %", 
            field: "fixed_markup_perc",
            minWidth: 125,
            valueGetter: getFixedMarkupPercentage,
            ...percentage
        },
        { 
            headerName: "Gross Profit %", 
            field: "gross_profit_after_ntp",
            minWidth: 125,
            valueGetter: getNTPGrossProfit,
            ...percentage
        },
        { 
            headerName: "Notes", 
            field: "notes",
            editable: isEditable,
            minWidth: 300
        },
        { 
            headerName: "Last Modified Time", 
            field: "last_modified_time",
            minWidth: 200,
            type: 'dateTime',
            valueFormatter: function (params) {
                if (params.value == null|| params.value === '') {
                    return null
                }
                else {
                    return moment(params.value).isValid() ? moment(params.value).format('MM/DD/YYYY HH:mm:ss') : '';
                }

            }
        },
        { 
            headerName: "Last Modified User", 
            field: "last_modified_user",
            minWidth: 125
        }

    ];

    // Form for New Row Confirmation
    const handleOpenNewRow = () => {
        setForm(fields);
        setOpen(true);
    };

    const handleCloseNewRow = () => {
        setOpen(false);
        setForm(fields);
    };

    // Dialogue for Delete Confirmation
    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
        };
      
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        };

    // Dialogue for Edit Confirmation
    const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
    };
  
    // Function to close the dialog
    const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    };

    // Dialogue for Delete Confirmation
    const handleOpenSummaryDrawer = (row) => {
        setSummaryDrawerRow(row);
        setSummaryDrawerOpen(true);
        };

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleCloseNTPSnackbar = () => {
        setNTPSnackbar({
            ...ntpSnackbar,
            open:false,
        });
    };

    const handleSummaryValueChange = (key, newValue) => {
        setSummaryEditableValues(prevValues => ({
          ...prevValues,
          [key]: newValue,
        }));
      };

    const handleSummarySave = () => {
        console.log(summaryEditableValues)
        setSummaryDrawerOpen(false);
      };
      
      

    const handleCopyNTPSnackbar = () => {
        const row_id = ntpValues.id

        setRows(prevRows => {
            const rowIndex = prevRows.findIndex(row => row.id === row_id);
            if (rowIndex === -1) return prevRows; // Row not found
    
            const updatedRows = [...prevRows];
            updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                NTP_amount: ntpValues.amount,
                estimated_cost_after_ntp: ntpValues.cost,
            };
    
            return updatedRows;
        });


        setEditedRows(prevRows => {
            return {
                    ...prevRows,
                    [row_id]: {
                        ...prevRows[row_id], 
                        NTP_amount: ntpValues.amount, 
                        estimated_cost_after_ntp: ntpValues.cost
                    }
                }
            })
    };

    const handleCSVFileLoaded = async (data) => {

        const reversedPeopleMapping = Object.fromEntries(
            Object.entries(peopleMapping).map(([key, value]) => [value, key])
          );

        const statusMapping = {'': null,
        'In Progress':'IN_PROGRESS',
        'Submitted':'SUBMITTED',
        'On Hold':'ON_HOLD',
        'Cancelled': "CANCELLED",
        'NTP':'NTP' }

        const budgetMapping = {'': null,
        'Not Ready':'NOT_READY',
        'Ready':'READY' }

        const acceptedHeaders = ["Contract ID",
        "WO #",
        "WO Title",
        "Proposal Status",
        "Estimator",
        "PM", 
        "RFP Date",
        "Job Walk Date", 
        "Due Date",
        "Submit Date",
        "Inquiry Subcon Date",
        "Budget Status", 
        "Reviewer",
        "Review Date",
        "Proposal Amount", 
        "Estimated Cost",
        "NTP Date",
        "NTP Amount",
        "Estimated Cost (NTP)",
        "Notes"];

        const headerToFieldMap = Object.fromEntries(
            acceptedHeaders.map((header, index) => [header, fieldNames[index]])
        );

        const contractIdKey = headerToFieldMap["Contract ID"] || "Contract ID";

        const payload = data.map(row => {
            return Object.keys(row).reduce((newRow, key) => {
                if (acceptedHeaders.includes(key)) {
                    // Use the headerToFieldMap to find the new key for this column
                    const newKey = headerToFieldMap[key];
                    newRow[newKey] = row[key];
                  }
                  return newRow;
              }, {});
            }).filter(row => row[contractIdKey] && row[contractIdKey].trim() !== "");

        const convertDateFormat = (dateStr) => {
            // Check for blank or null values
            if (!dateStr) {
                return null;
            }
        
            const formattedDate = moment(dateStr);

            if (!formattedDate.isValid()) {
                throw new Error(`Invalid date format: ${dateStr}`);
            }
        
            return formattedDate.format('YYYY-MM-DD');
        };

        payload.forEach((row) => {
            statusFields.forEach(field=> {
                if (row[field] === "" || row[field] == null) {
                    row[field] = "";
                  }
                else {
                    row[field] = statusMapping[row[field]]
                }
            });
            budgetStatusFields.forEach(field=> {
                if (row[field] === "" || row[field] == null) {
                    row[field] = "";
                  }
                else {
                    row[field] = budgetMapping[row[field]]
                }
            })
            peopleFields.forEach(field=> {
                if (row[field]) {
                    row[field] = reversedPeopleMapping[row[field]];
                  }
                });
            dateFields.forEach(field => {
                if (row[field] && row[field].trim() !== '') {
                    row[field] = convertDateFormat(row[field]);
                }
                else {
                    row[field] = null;
                }
            });
            numberFields.forEach(field => {
                if (row[field] !== "" && row[field] != null) {
                    // Enhanced to remove currency symbols and other non-numeric characters except minus sign and decimal
                    const cleanValue = row[field].toString().replace(/[^0-9.-]+/g, '');
                    const floatValue = parseFloat(cleanValue);
            
                    if (isNaN(floatValue)) {
                        throw new Error(`Invalid number format for key '${field}': ${row[field]}`);
                    }
            
                    row[field] = floatValue;
                } else {
                    row[field] = null;
                }
            });
        });

        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/projects/upload_projects/`, JSON.stringify(payload), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const highestId = Math.max(...rows.map((row) => row.id), 0);
            const newRows = response.data.new_rows.map((item, index) => ({
                id: highestId + index + 1,
                ...item,
            }));
    
            setRows((prev) => [
                ...prev,
                ...newRows
            ]);
    
            setSnackbar({ children: 'Successfully created new rows', severity: 'success' });
    
        } catch (error) {
            setSnackbar({ children: 'Submit went wrong', severity: 'error' });
            console.error('Error:', error);
        }
    };

    const handleFileLoaded = async (data) => {

        const reversedPeopleMapping = Object.fromEntries(
            Object.entries(peopleMapping).map(([key, value]) => [value, key])
          );

        const statusMapping = {'': null,
        'In Progress':'IN_PROGRESS',
        'Submitted':'SUBMITTED',
        'On Hold':'ON_HOLD',
        'Cancelled': "CANCELLED",
        'NTP':'NTP' }

        const budgetMapping = {'': null,
        'Not Ready':'NOT_READY',
        'Ready':'READY' }

        const acceptedHeaders = ["Contract ID",
        "WO #",
        "WO Title",
        "Proposal Status",
        "Estimator",
        "PM", 
        "RFP Date",
        "Job Walk Date", 
        "Due Date",
        "Submit Date",
        "Inquiry Subcon Date",
        "Budget Status", 
        "Reviewer",
        "Review Date",
        "Proposal Amount", 
        "Estimated Cost",
        "NTP Date",
        "NTP Amount",
        "Estimated Cost (NTP)",
        "Notes"];

        const headerToFieldMap = Object.fromEntries(
            acceptedHeaders.map((header, index) => [header, fieldNames[index]])
          );

        if (!data[0]) {
            setSnackbar({ children: 'Invalid file format: Missing headers', severity: 'error' });
            return;
        }

        // clean end of line \r values
        let cleanedHeaders = data[0].map(header => header.replace(/\r?\n|\r|\$/g, ''));

        const isHeaderValid = acceptedHeaders.every(header => cleanedHeaders.includes(header));
        if (!isHeaderValid) {
            setSnackbar({ children: 'Invalid file format: Missing required headers', severity: 'error' });
            return;
        }

        let cleanedData = data
            .filter(row => !row.every(field => field === "" || field == null)) // Skip rows where all fields are empty or null
            .map(row => 
                row.map(field => typeof field === 'string' ? field.replace(/\r?\n|\r|\$/g, '') : field ?? null)
            );

        // Filter out rows without a contract ID
        const contractIdIndex = cleanedHeaders.findIndex(header => header === "Contract ID");
        cleanedData = cleanedData.filter(row => row[contractIdIndex] && row[contractIdIndex].trim() !== "");

       // Create a list of valid header indices
        const validIndices = cleanedHeaders.map((header, index) => acceptedHeaders.includes(header) ? index : null).filter(index => index !== null);

        const validFieldNames = cleanedHeaders.map(header => headerToFieldMap[header]).filter(Boolean);


        // Use validIndices to filter both headers and data
        cleanedHeaders = cleanedHeaders.filter((_, index) => validIndices.includes(index));

        cleanedData = cleanedData.map(row => row.filter((_, index) => validIndices.includes(index)));

        // Check if any valid headers are left
        if (cleanedHeaders.length === 0) {
            setSnackbar({ children: 'Invalid file format: No matching columns found', severity: 'error' });
            return;
        }


        // Exclude the header row and zip rows into dictionaries
        const payload = cleanedData.slice(1).map(row => {
            return Object.fromEntries(row.map((cell, index) => [validFieldNames[index], cell]));
        });

        const convertDateFormat = (dateStr) => {
            // Check for blank or null values
            if (!dateStr) {
                return null;
            }
        
            const formattedDate = moment(dateStr);

            if (!formattedDate.isValid()) {
                throw new Error(`Invalid date format: ${dateStr}`);
            }
        
            return formattedDate.format('YYYY-MM-DD');
        };

        payload.forEach((row) => {
            statusFields.forEach(field=> {
                if (row[field] === "" || row[field] == null) {
                    row[field] = "";
                  }
                else {
                    row[field] = statusMapping[row[field]]
                }
            });
            budgetStatusFields.forEach(field=> {
                if (row[field] === "" || row[field] == null) {
                    row[field] = "";
                  }
                else {
                    row[field] = budgetMapping[row[field]]
                }
            })
            peopleFields.forEach(field=> {
                if (row[field]) {
                    row[field] = reversedPeopleMapping[row[field]];
                  }
                });
            dateFields.forEach(field => {
                if (row[field] && row[field].trim() !== '') {
                    row[field] = convertDateFormat(row[field]);
                }
                else {
                    row[field] = null;
                }
            });
            numberFields.forEach(field => {
                if (row[field] !== "" && row[field] != null) {
                    // Enhanced to remove currency symbols and other non-numeric characters except minus sign and decimal
                    const cleanValue = row[field].toString().replace(/[^0-9.-]+/g, '');
                    const floatValue = parseFloat(cleanValue);
            
                    if (isNaN(floatValue)) {
                        throw new Error(`Invalid number format for key '${field}': ${row[field]}`);
                    }
            
                    row[field] = floatValue;
                } else {
                    row[field] = null;
                }
            });
        });

        try {
            const response = await axios.post(`${config.apiBaseUrl}/api/projects/upload_projects/`, JSON.stringify(payload), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const highestId = Math.max(...rows.map((row) => row.id), 0);
            const newRows = response.data.new_rows.map((item, index) => ({
                id: highestId + index + 1,
                ...item,
            }));
    
            setRows((prev) => [
                ...prev,
                ...newRows
            ]);
    
            setSnackbar({ children: 'Successfully created new rows', severity: 'success' });
    
        } catch (error) {
            setSnackbar({ children: 'Submit went wrong', severity: 'error' });
            console.error('Error:', error);
        }
    };

    function findDifference(dict1, dict2) {
        const difference = {};
      
        // Iterate over keys of dict1
        for (const key in dict1) {
          // Check if the values are not equal
          if (dict1[key] !== dict2[key]) {
            difference[key] = dict1[key];
          }
        }
      
        return difference;
      }
    
      function updateDictionary(dict, difference) {
        const updatedDict = { ...dict };

        for (const key in difference) {
            if (difference.hasOwnProperty(key)) {
          updatedDict[key] = difference[key];
        }
      }
      return updatedDict
    }

    function getEstimatedAmount(params) {
        return params.row.estimated_prop_amt;
      }


    function getEstimatedMarkup(params) {
        return getEstimatedAmount(params) - params.row.estimated_cost
        }
        
    function getEstimatedMarkupPercentage(params) {
        const estimatedMarkup = getEstimatedMarkup(params);
        return ((estimatedMarkup / params.row.estimated_cost) * 100).toFixed(2);
    }
    
    function getGrossProfit(params) {
        const estimatedAmount = getEstimatedAmount(params);
        const estimatedMarkup = getEstimatedMarkup(params);
        return ((estimatedMarkup / estimatedAmount) * 100).toFixed(2);
    }
    
    function getFixedMarkup(params) {
        return params.row.NTP_amount - params.row.estimated_cost_after_ntp;
    }

    function getFixedMarkupPercentage(params) {
        const estimatedMarkup = getFixedMarkup(params);
        return ((estimatedMarkup / params.row.estimated_cost_after_ntp) * 100).toFixed(2);
    }

    function getNTPGrossProfit(params) {
        const estimatedAmount = params.row.NTP_amount
        const estimatedMarkup = getFixedMarkup(params);
        return ((estimatedMarkup / estimatedAmount) * 100).toFixed(2);
    }

    const handleEditCellChange = useCallback(
        (params, params2) => {
            if (typeof params !== 'object' || params === null || !params.hasOwnProperty('id')) {
                console.error("Invalid params passed to handleEditCellChange");
                return;
            }

            const difference = findDifference(params, params2)
            if ("NTP_date" in difference) {
                
                setNTPValues({id: params2.id, amount: params2.estimated_prop_amt, cost: params2.estimated_cost})
                setNTPSnackbar({ open: true, Transition: Slide });
            }
            const updatedRows = editedRows ? { ...editedRows} : {};
            const id = params.id
            updatedRows[id] = updatedRows[id] || {};
            updatedRows[id]["id"] = id

            setRows(prevRows => {
                const rowIndex = prevRows.findIndex(row => row.id === id);
                const updatedRows = [...prevRows];
                if (rowIndex !== -1) {
                    for (let field in difference) {
                        if (field === 'id') continue;
    
                        if (dateFields.includes(field)) {
                            if (difference[field] === "Invalid date") {
                                updatedRows[rowIndex][field] = null;
                            } else {
                                let date = new Date(difference[field]);
                                let isoFormattedDateString = date.toISOString().split('T')[0];
                                updatedRows[rowIndex][field] = isoFormattedDateString;
                            }
                        } else {
                            updatedRows[rowIndex][field] = difference[field];
                        }
                    }
                }
                return updatedRows;
            });


            for (let field in difference) {
                if (field === 'id') continue;

                // Handle date field formatting
                if (dateFields.includes(field)) {
                    if (difference[field] === "Invalid date") {
                        updatedRows[id][field] = null;
                    }
                    else {
                        let date = new Date(difference[field]);
                        let isoFormattedDateString = date.toISOString().split('T')[0];
                        updatedRows[id][field] = isoFormattedDateString;
                    }
                }
                else {
                    updatedRows[id][field] = difference[field];
                }
            }
            setEditedRows(updatedRows)

            return updatedRows[params.id];
    },
        [editedRows]
      );

    const handleProcessRowUpdateError = React.useCallback((error) => {
            setSnackbar({ children: 'row update went wrong', severity: 'error' });
          }, []);

    const handleInputChange = (event, newValue = null, fieldName = null) => {
        if (event && event.target && event.target.name) {
            setForm({
            ...form,
            [event.target.name]: event.target.value || "",
            });
        } else if (newValue && fieldName) {
            setForm({
            ...form,
            [fieldName]: newValue || "",
            });
        }
    };

    const handleSubmit = () => {
        form['last_modified_time'] = moment();
        form['last_modified_user'] = username;
        axios.post(`${config.apiBaseUrl}/api/projects/`, form, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }            
        })
        .then((response) => {
        setSnackbar({ children: 'Successfully created new row', severity: 'success' });
        const highestId = Math.max(...rows.map((row) => row.id), 0);
        setRows((prev) => [
            ...prev,
            {
            id: highestId + 1,
            ...response.data,
            },
        ]);
        })
        .catch((error) => {
        setSnackbar({ children: 'Submit went wrong', severity: 'error' });
        console.error('Error:', error);
        });

    handleCloseNewRow();
    };
    

    const handleConfirmEdit = () => {
        selectedRows.forEach((id) => {
            const rowChanges = editedRows[id];
            const originalRow = rows.find(dict => dict.id === id);
            const url = `${config.apiBaseUrl}/api/projects/${id}/`;
             // Ensure we found a matching row
            if (!rowChanges) {
                console.error(`Could not find row with id ${id} in current state.`);
                return;
            }

            const updatedRow = updateDictionary(originalRow, rowChanges)
            for (let field in updatedRow) {
                if (field === 'id') continue;

                // Handle date field formatting
                if (dateFields.includes(field)) {
                    if (moment(updatedRow[field]).isValid()) {
                        updatedRow[field] = moment(updatedRow[field]).format('YYYY-MM-DD');
                    } else {
                        updatedRow[field] = null;
                    }
                }
            }

            updatedRow['last_modified_time'] = moment();
            updatedRow['last_modified_user'] = username;
            axios.put(url, updatedRow, {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                }
              })
                .then((response) => {
                    if (response.status === 200) {
                        setSnackbar({ children: 'Successfully saved changes', severity: 'success' });
                        setRows((prev) =>
                        prev.map((row) => {
                            if (row.id !== id) {
                            return row;
                            }

                            return { ...row, ...response.data };
                        })
                        );
                        fetchProjectCounts(config, setProjectCounts);
                        fetchEstimatorProjectCounts(config, setEstimatorProjectCount, EstimatorProjectCount);
                    }
                else {
                    // Handle any other statuses or errors
                    throw new Error('Failed to save edits.');
                  }                
            })
            .catch((error) => {
                setSnackbar({ children: 'Confirm edit went wrong', severity: 'error' });
                console.error('Error:', error);
            });
        handleCloseEditDialog();
    });
    }
    
    const handleDelete = () => {
        axios.post(`${config.apiBaseUrl}/api/projects/bulk-delete/`, { ids: selectedRows }, { withCredentials: true })
          .then(() => {
            setRows((prev) => prev.filter((row) => !selectedRows.includes(row.id)));
            setSnackbar({ children: 'Successfully deleted row(s)', severity: 'success' });
          })
          .catch((error) => {
            setSnackbar({ children: 'Delete went wrong', severity: 'error' });
            console.error('Error:', error);
          });
      
        handleCloseDeleteDialog();
      };

    
    function AggregationMenuItem(props) {
        const { aggregationHandler, columnName } = props;
        return (
            <MenuItem onClick={() => aggregationHandler(columnName)}>
            <ListItemIcon>
                <FunctionsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{`Toggle Aggregation`}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(props) {
        return (
          <GridColumnMenu
            {...props}
            slots={{
                columnMenuUserItem: AggregationMenuItem
              }}
              slotProps={{
                columnMenuUserItem: {
                  displayOrder: 15,
                  columnName: props.colDef.field,
                  aggregationHandler: handleToggleAggregation
                },
              }}
            />
          );
        }

      const handleToggleAggregation = (field) => {
        setSelectedColumns((prevSelectedColumns) =>
          prevSelectedColumns.includes(field)
            ? prevSelectedColumns.filter((col) => col !== field)
            : [...prevSelectedColumns, field]
        );
      };

    //   function escapeRegExp(value) {
    //     return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    //   }
      
    
    // const requestSearch = (searchValue) => {
    //     setSearchText(searchValue);
    //     const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    //     const filteredRows = data.rows.filter((row) =>
    //       Object.keys(row).some((field) => searchRegex.test(row[field].toString()))
    //     );
    //     setRows(filteredRows);
    // };
      

    const calculateSum = (rows, field) => {
        return rows.reduce((acc, row) => acc + parseFloat(row[field] || 0), 0);
    };
    
    const AggregatorToolbar = () => {
        const apiRef = useGridApiContext();
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumns = gridVisibleColumnFieldsSelector(apiRef);

        const filteredRows = filteredSortedRowIds.map((id) => {
            const row = apiRef.current.getRow(id);
            return Object.keys(row)
              .filter((key) => visibleColumns.includes(key))
              .reduce((obj, key) => {
                obj[key] = row[key];
                return obj;
              }, {});
          });

        const sums = selectedColumns.reduce((acc, column) => {
            acc[column] = calculateSum(filteredRows, column);
            return acc;
          }, {}); // Using the calculateSum function
    
    return (
        <GridToolbarContainer style={{ backgroundColor: '#F1F1F1'}}>
      <Box display="flex" justifyContent="space-between" width="100%">
        {/* First toolbar section (e.g., your existing toolbar customizations) */}
        <div>
        <CustomToolbar onCSVFileLoaded={handleCSVFileLoaded} onFileLoaded={handleFileLoaded} foreignkeyfields={peopleFields} foreignkeymapping={peopleMapping} />
        </div>
        </Box>
        {/* Second row with the aggregations */}
        <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
            {visibleColumns.map((column) => (
            selectedColumns.includes(column) && ( // Move this condition outside the Box
            <Box key={column} flexBasis={0} flexGrow={1} maxWidth="100%" marginRight={1} marginBottom={1}>
                <MainCard contentSX={{ p: 2.25 }} sx={{
                    width:'auto',
                    height:'auto',
                    border: '2px',
                    bgcolor: 'rgba(250, 250, 250, 0.3)',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px' }}>
                    <Typography variant="h7" fontFamily='"Inter"'>
                        {`Sum of ${fieldNameMapping[column]}:`}
                    </Typography>
                    <Typography variant="h6" fontFamily='"Inter"'>
                        {`$${commafy(ccyFormat(sums[column]))}`}
                    </Typography>
                </MainCard>
            </Box>
            )
            ))}
        </Box>
    </GridToolbarContainer>
  );
};

// STYLING
    const theme = createTheme({
        palette: {
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
          secondary: {
            light: '#C5E3EC',
            main: '#1AA7EC',
            dark: '#AADDEC',
            contrastText: '#000',
          },
        },
        typography: {
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
              'Poppins',
              'Inter'
            ].join(','),
          },
      });
    if (loading) return (<ThemeProvider theme={theme}>
        <Box sx={{display: 'flex'}}
        minHeight="50vh" alignItems="center"
        justifyContent="center">
        <CircularProgress sx = {{color:'secondary'}}/>
        </Box>
        </ThemeProvider>
        );     

    return (
        <>

        {/* Interactive Buttons */}
            <Box style={{ margin: '0px' }}>
            <ThemeProvider theme={theme}>
                <div style={{ marginTop: '1vh' }}>
                <Grid container rowSpacing={4.5} columnSpacing={2.75} padding={1.5}
                    style={{ margin: 0, width: '93%', boxSizing: 'border-box', marginRight: 5 }}>
                    {/* row 1 */}
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="h4"  style={{ fontFamily: 'Inter' }}>Total Estimation Proposals:&nbsp;</Typography>
                        </Grid>
                        <Grid item>
                            <CountUp  style={{ fontFamily: 'Poppins' }} fetchedValue={numprojects} duration={1500} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0.5} justifyContent="space-evenly" style={{ marginTop: '20px' }}>
                        {Object.keys(statusMapping).map((status) => (
                            <Grid item xs={12} sm={6} md={4} lg={2} key={status}>
                                <EstimatorProjects
                                    status={status} 
                                    projectCount={projectCounts[status]} 
                                    estimatorCount={EstimatorProjectCount[status]}
                                    statusMapping={statusMapping}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
                <div style={{
                        display: 'flex', // Enables flexbox
                        justifyContent: 'flex-end', // Aligns children (the buttons) to the right
                        width: 'calc(95%)',
                        height: '10%', // Full height of the container (adjust as needed)
                        padding: '5px', // Adds some space inside the container's edges
                        }}>            
                    <Button
                        sx={{ mx: 0.3 }}
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleOpenNewRow}
                        >
                        Add
                    </Button>
                    <Button 
                        sx={{ mx: 0.3 }}
                        variant ="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={handleOpenDeleteDialog}>
                            Delete
                    </Button>
                    <Button 
                        sx={{ mx: 0.3 }}
                        variant ="contained"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={handleOpenEditDialog}>
                            Save Edits
                    </Button>
                </div>

        {/* Add New Row Form */}
            <Dialog open={open} onClose={handleCloseNewRow}
            fullWidth={true} // this sets it to take full width of the screen
            maxWidth="md" // adjust to 'sm', 'md', 'lg', 'xl' as per your need
            >
                <DialogTitle>Add new row</DialogTitle>
                <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={3}>
                    {fieldLabels.map((field, index) => {

                    const isStatusField = statusFields.includes(field.name);
                    const isPeopleField = peopleFields.includes(field.name);
                    const isDateField = dateFields.includes(field.name);
                    const isBooleanField = booleanFields.includes(field.name);
                    const isBudgetStatusField = budgetStatusFields.includes(field.name);

                    return (
                        <Grid item xs={6} key={field.name} > {/* xs={6} means it takes up half of the grid */}
                        {(isStatusField || isPeopleField || isBudgetStatusField ) ? (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' , marginTop: '16px' }}>
                        <FormControl key={field.name} variant="outlined" fullWidth >
                            <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                            <Select
                                name={field.name}
                                value={form[field.name]}
                                onChange={(event) => {
                                    handleInputChange(event);
                                  }}
                                input={
                                    <OutlinedInput label={field.label} name={field.name} id={field.name} />
                                    }
                            >
                                {(isStatusField ? statusOptions: isBudgetStatusField? budgetStatusOptions : isPeopleField ? peopleOptions : []).map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        </Box>
                        
                    ) : isDateField ? (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' , marginTop: '16px' }}>
                        <DatePicker
                          label={field.label}
                          value={form[field.name]}
                          onChange={(newValue) => {
                            handleInputChange(null, moment(newValue.$d).format('YYYY-MM-DD'), field.name);
                          }}
                          slotProps={{ textField: { variant: 'outlined', fullWidth: true } }}
                        />
                        </Box>
                      ) : isBooleanField ? (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' , marginTop: '16px' }}>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel htmlFor={field.name}>{field.label}</InputLabel>
                                <Select
                                    name={field.name}
                                    value={form[field.name]}
                                    onChange={handleInputChange}
                                    input={<OutlinedInput label={field.label} name={field.name} id={field.name} />}
                                >
                                    <MenuItem value={true}>Completed</MenuItem>
                                    <MenuItem value={false}>Uncompleted</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    ) : (
                        <Box mt={1} style={{ height: '56px', boxSizing: 'border-box' }}>
                    <RowAddTextField
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        value={form[field.name]}
                        onChange={handleInputChange}
                    />
                    </Box>
                        )}                        
                    </Grid>
                    );
                    }
                    )}
                </Grid>
                </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewRow}>Cancel</Button>
                    <Button onClick={handleSubmit}>Add</Button>
                </DialogActions>
            </Dialog>   
            
            {/* Edit Confirmation Box  */}
            <Dialog
                open={editDialogOpen}
                onClose={handleCloseEditDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Commit Changes?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Do you confirm to edit these rows?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                    No
                    </Button>
                    <Button onClick={handleConfirmEdit} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
                </Dialog>

            
            {/* Delete Confirmation Box  */}

            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Delete Rows?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Do you confirm to delete these rows?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                    No
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                    Yes
                    </Button>
                </DialogActions>
                </Dialog>

                {/* Data Grid Parameters */}
                <Box sx={{ height: 'auto', overflow: 'hidden', margin: 'auto', width: '90vw', maxWidth: '95vw', minWidth:'70vw'}}>
                    <DataGrid
                        density="compact"
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        stickyHeader
                        slots={{ columnMenu: (props) => <CustomColumnMenu {...props} toggleAggregation={handleToggleAggregation} />,
                                toolbar: AggregatorToolbar
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                              },
                        }}
                        processRowUpdate={(updatedRow, originalRow) =>
                            handleEditCellChange(updatedRow, originalRow)
                        }
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}                        
                        initialState={{
                            ...rows.initialState,
                            columns: {
                                ...rows.initialState?.columns,
                                columnVisibilityModel: {
                                id: false,
                                job_walk: false,
                                proposal_due_date: false,
                                budget_status: false,
                                inquiry_subcon_date: false,
                                review_date: false,
                                last_modified_time: false,
                                last_modified_user: false
                                },
                            },
                            }}
                            sx={{
                                width: 'auto',
                                margin: 'auto',
                                fontWeight: 400,
                                m:2,
                                fontSize: 14,
                                boxShadow: 3,
                                borderRadius: 0,
                                border: '2px solid #E7E7E7',
                                fontFamily: [
                                    '-apple-system',
                                    'BlinkMacSystemFont',
                                    '"Segoe UI"',
                                    'Roboto',
                                    '"Helvetica Neue"',
                                    'Arial',
                                    'sans-serif',
                                    '"Apple Color Emoji"',
                                    '"Segoe UI Emoji"',
                                    '"Segoe UI Symbol"',
                                  ].join(','),
                                  WebkitFontSmoothing: 'auto',
                                  letterSpacing: 'normal',
                                '& .MuiDataGrid-main': {  // This targets the main grid area excluding the toolbar
                                    border: '2px solid rgba(0, 0, 0, 0.2)', // Darker border for the data grid itself
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                                    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
                                    // add more css for customization
                                },
                                '.MuiDataGrid-row:last-child .MuiDataGrid-cell': {
                                    borderBottom: 'none',
                                },
                                '.MuiDataGrid-colCell': {
                                borderRight: '1px solid #ccc',
                                },
                                '.MuiDataGrid-colCell:last-child': {
                                borderRight: 'none',
                                },
                                '& .MuiDataGrid-row:nth-of-type(odd)': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slight grey for odd rows
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                  position: "sticky",
                                  border: 0,
                                  borderTop: 0,
                                  borderBottom: 1,
                                  borderRadius: 0,
                                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    marginTop: "0 !important"
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                                    width: '0.4em',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                                    background: '#f1f1f1',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888',
                                },
                                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                    background: '#555',
                                },
                            }}
                    />
                </Box>
                <Drawer
                    anchor="right"
                    open={summaryDrawerOpen}
                    onClose={() => setSummaryDrawerOpen(false)}
                >
                <div style={{ padding: 20, minWidth: 300 }}>
                    <IconButton onClick={() => setSummaryDrawerOpen(false)} style={{ marginLeft: 'auto' }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" gutterBottom>Summary</Typography>
                    <List>
                    {summaryDrawerRow && Object.entries(summaryDrawerRow).map(([key, value]) => {
                        if (!fieldNameMapping[key]) {
                            // Skip this iteration if there's no mapping for the key
                            return null;
                        }
                        return (
                        <React.Fragment key={key}>
                            <ListItem>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{`${fieldNameMapping[key] ? fieldNameMapping[key]: ''}:`}</Typography>
                                <TextField
                                    value={value}
                                    onChange={(e) => handleSummaryValueChange(key, e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    style={{ marginLeft: 8, flex: 1 }}
                                  />
                                {/* <Typography variant="subtitle2" style={{ marginLeft: 8 }}> {formatValue(key, value)}</Typography> */}
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </React.Fragment>
                        );
                    })}
                    </List>
                    <Button
                        variant="contained" 
                        color="primary" 
                        onClick={handleSummarySave}
                        style={{ marginTop: 20 }}
                    >
                    Save Changes
                    </Button>
                </div>
                </Drawer>
                </Grid>
                </div>
                {!!ntpSnackbar && (
                    <Snackbar
                    open={ntpSnackbar.open}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={handleCloseNTPSnackbar}
                    autoHideDuration={8000}
                    key={ntpSnackbar.Transition.name}
                    message="Copy over NTP values from estimates?"                    
                    action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={handleCopyNTPSnackbar}>
                        COPY
                        </Button>
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={handleCloseNTPSnackbar}
                        >
                        <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                    }
                    >
                    </Snackbar>
                )}       
                {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={100000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </ThemeProvider>
                
            </Box>
            
            
    </>
    );
}

export default TableComponent;